import { FB_PIXEL_IDS } from "@/lib/fpixel";
import Script from "next/script";
import React from "react";
import { selectGamize } from "@/store/slices/gamize";
import { useSelector } from "react-redux";
import { selectAuth } from "@/store/slices/auth";

const ExternalScripts = () => {
  const gamizeState = useSelector(selectGamize);
  const authState = useSelector(selectAuth);
  return (
    <>
      <Script id="fb-pixel" strategy="afterInteractive">
        {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');

            ${FB_PIXEL_IDS.map((v) => `fbq('init', ${v});`).join("")}
          `}
      </Script>
      {/* Google Tag Manager */}
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=GTM-P69KMZ9"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'GTM-P69KMZ9', {'allow_enhanced_conversions':true});
        `}
      </Script>
      {/* End Google Tag Manager */}
      <Script strategy="afterInteractive" id="db-page-scripts">
        {`
          function mGoldBookingBtnClick() {
            var x = document.getElementsByClassName("mGoldBookingCheck")[0].checked;
            if (x) {
              window.location.href = "https://sencogoldanddiamonds.com/gold-booking/";
            } else {
              alert("You must accept the terms and conditions to proceed");
            }
          }

          function mSilverBookingBtnClick() {
            var x = document.getElementsByClassName("mSilverBookingCheck")[0].checked;
            if (x) {
              window.location.href = "https://sencogoldanddiamonds.com/silver-booking/";
            } else {
              alert("You must accept the terms and conditions to proceed");
            }
          }
          function openCity(evt, cityName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(cityName).style.display = "block";
            evt.currentTarget.className += " active";
          }

          function irShowSection(id) {
            const ids = ['FinancialResults', 'GroupCompanyFinancials', 'AppointmentIndependentDirector', 'AnnualReturnMGT', 'Prospectus', 'TradingWindowClosure', 'Recordings', 'NewspaperPublications'];
            ids.forEach(v => {
                if (document.getElementById(v)) {
                    document.getElementById(v).style.display = 'none';
                }
            });
            if (document.getElementById(id)) {
                document.getElementById(id).style.display = 'block';
            }
          }

          function importantDisclaimerBtnClick() {
            const confirmRadio = document.getElementById("important-disclaimer-confirm");
            const notConfirmRadio = document.getElementById("important-disclaimer-not-confirm");
            document.getElementById("important-disclaimer-message").innerText = "";
            
            if (confirmRadio.checked) {
              const urlParams = new URLSearchParams(window.location.search);
              const redirectUrl = urlParams.get("redirect");
              
              if (redirectUrl) {
                window.location.href = redirectUrl;
              }
            } else if (notConfirmRadio.checked) {
              document.getElementById("important-disclaimer-message").innerText = "You are not permitted to view the materials in this section of the website.";
            }
          }
        `}
      </Script>
      {/* Fireworks */}
      <Script async src="https://asset.fwcdn3.com/js/storyblock.js" />
      <Script async src="https://asset.fwcdn3.com/js/embed-feed.js" />
      <Script async src="https://asset.fwcdn3.com/js/fwn.js" />
      {!!gamizeState.gamizeData?.script && (
        <>
          <Script async src={`${process.env.NEXT_PUBLIC_GAMIZE_JS_URL}`} />
          <Script
            async
            src={`${process.env.NEXT_PUBLIC_GAMIZE_API}/custom_gamiz.js`}
            onLoad={() => {
              try {
                //@ts-ignore
                window.loadGamize(
                  gamizeState.gamizeData?.script,
                  authState.user?.mobile
                );
              } catch (error) {}
            }}
          />
        </>
      )}

      <Script strategy="afterInteractive" id="moengage">
        {`!function(e,n,i,t,a,r,o,d){var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage");
          Moengage = moe({
          app_id:"${process.env.NEXT_PUBLIC_MOENGAGE_ID}" ,
          debug_logs: ${parseInt(
            process.env.NEXT_PUBLIC_MOENGAGE_DEBUG || "1"
          )},
          cluster: "DC_3",
          swPath: "${process.env.NEXT_PUBLIC_BASE_PATH || ""}/serviceworker.js",
          ${
            process.env.NEXT_PUBLIC_BASE_PATH
              ? `swScope: "${process.env.NEXT_PUBLIC_BASE_PATH}/"`
              : ""
          }
          
          });
        `}
      </Script>
      <Script
        strategy="afterInteractive"
        src="//cdn.syteapi.com/assets/imajs/imajs.js?a=10580&sig=65702a19b9d335ca4a5304ff&languageCode=en_US"
      />
      <Script
        src="https://cdn.styledotme.com/general/scripts/mirrar-ui.js"
        strategy="afterInteractive"
      ></Script>
      <Script id="hybrid-analytics" strategy="afterInteractive">
        {`
          window._txq = window._txq || [];
          var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = '//st.hybrid.ai/txsp.js';
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(s);
          _txq.push(['createPixel', '639c9f377bc72f4388cba645']);
          _txq.push(['track', 'PageView']);
        `}
      </Script>
    </>
  );
};

export default ExternalScripts;
