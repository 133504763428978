export const gtagTrackEvent = (action: string, event: string, data: any) => {
  const maxRetries = 20;
  let retryCount = 0;
  let eventPushed = false;
  let intervalId: any;

  const tryPushEvent = () => {
    if (window.gtag) {
      window.gtag(action, event, data);
      clearInterval(intervalId);
      eventPushed = true;
      console.log("gtag tracked");
    } else {
      retryCount++;
      if (retryCount >= maxRetries) {
        clearInterval(intervalId);
        if (!eventPushed) {
          console.error("Maximum retry count reached. Gtag event not tracked.");
        }
      }
    }
  };

  tryPushEvent();

  if (!eventPushed) {
    intervalId = setInterval(tryPushEvent, 1000);
  }
};
