import { useAppDispatch } from "@/store";
import { authActions, fetchAuthUser, selectAuth } from "@/store/slices/auth";
import { fetchCartItems } from "@/store/slices/cart";
import { fetchWishlistItems } from "@/store/slices/wishlist";
import "@/styles/react-zoom-slider.scss";
import "@/styles/globals.scss";
import "@/styles/house-of-senco.scss";
import "@/styles/animation.scss";
import "@/styles/swiper.css";
import "animate.css";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { SSRProvider } from "react-bootstrap";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "tippy.js/dist/tippy.css";
import { wrapper } from "../store";
import { pageview } from "@/lib/fpixel";
import ExternalScripts from "@/components/external-scripts";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { useSelector } from "react-redux";
import { useTimeout } from "usehooks-ts";
import { initTryOnTracking } from "@/lib/tryOnTracking";
import moment from "moment-timezone";
import { gamizeActions } from "@/store/slices/gamize";
import httpClient from "@/api/http-client";
import endpoints from "@/api/endpoints";
import { getCookie, setCookie } from "cookies-next";
import { gtagTrackEvent } from "@/lib/gtag";

declare global {
  interface Window {
    initMirrarUI?: (sku: string, config: { brandId: string }) => void;
    fbq?: (event: string, name: string, options?: any) => void;
    gtag?: any;
    dataLayer?: any;
    Moengage?: any;
    syteDataLayer?: any;
  }
  namespace JSX {
    interface IntrinsicElements {
      "fw-storyblock": any;
      "fw-embed-feed": any;
      "fw-herounit": any;
    }
  }
}

NProgress.configure({
  minimum: 0.3,
  easing: "ease",
  speed: 800,
  showSpinner: true,
});

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const authState = useSelector(selectAuth);

  useEffect(() => {
    initTryOnTracking();
    dispatch(fetchAuthUser()).then((data) => {
      if (data.payload) {
        dispatch(fetchWishlistItems(data.payload.id));
        initTryOnTracking(data.payload.id);
      }
    });
    dispatch(fetchCartItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const analytics = async () => {
    try {
      const url = new URL(document.referrer);

      if (url?.hostname) {
        if (getCookie("referrer")) {
          if (
            getCookie("referrer") !== url?.hostname &&
            url.hostname !== location.hostname
          ) {
            getAnalytics();
          }
        } else {
          getAnalytics();
        }
      }
    } catch (error) {}
  };

  const getAnalytics = async () => {
    const url = new URL(document.referrer);
    try {
      const { data } = await httpClient.post(endpoints.analytics.track(), {
        url: url?.hostname,
      });

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1);
      if (data.status) {
        setCookie("referrer", url?.hostname, {
          expires: expirationDate,
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!router.route.startsWith("/checkout/status")) {
      analytics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const offerModalOpen = async () => {
    if (router.asPath == "/" && !authState.user && !authState.isModalOpen) {
      dispatch(authActions.setIsOfferModalOpen(true));
    }

    // moment.tz.setDefault("Asia/Kolkata");
    // const currentDate = moment();
    // const endDate = moment("2024-04-01", "YYYY-MM-DD").endOf("day");
    // if (currentDate.isSameOrBefore(endDate)) {
    // }
  };

  useTimeout(offerModalOpen, 3000);

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    pageview();

    const handleRouteChange = () => {
      pageview();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  const loadGamize = async () => {
    try {
      const { data } = await httpClient.post(endpoints.gamize.load(), {
        mobile: authState.user?.mobile,
        platform: "senco",
      });

      dispatch(gamizeActions.setGamize(data));
    } catch (error: any) {
      console.log(error);
    }
  };
  const isConcent = async () => {
    try {
      const { data } = await httpClient.get(endpoints.auth.getConsentForm());
      if (data.status) {
        dispatch(authActions.setIsConsentModalOpen(true));
      }
    } catch (error: any) {}
  };
  useEffect(() => {
    if (authState.user?.name?.length) {
      loadGamize();
      isConcent();
    } else {
      dispatch(gamizeActions.setGamize(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.user, dispatch, router.asPath]);

  useEffect(() => {
    if (authState.user?.duplicate_records?.length) {
      dispatch(authActions.setIsAccountSelectionModalOpen(true));
    } else {
      dispatch(authActions.setIsAccountSelectionModalOpen(false));
    }
  }, [authState.user?.duplicate_records, dispatch]);

  useEffect(() => {
    if (authState.user) {
      gtagTrackEvent("set", "user_data", {
        email: authState.user.email,
        phone_number: `+91${authState.user.mobile}`,
      });
    }
  }, [authState.user]);

  const getLayout = Component.getLayout ?? ((page) => page);

  return getLayout(
    <SSRProvider>
      <ExternalScripts />
      <Component {...pageProps} />
    </SSRProvider>
  );
}

export default wrapper.withRedux(MyApp);
